import { InternalTitlePermissionListItem } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { Breadcrumb, BreadcrumbIconsAction } from './../utilities/internal-share/models/model';
import { PipelineLead, ScreenSizeInfo, ChatMessage } from './interfaces';
import { MenuGroupModel, MenuItemModel, NotificationReceiveInfoModel } from './models';

// export class StoreState{
//     public selectedContact: PipelineLead | undefined;
//     public isLoggedIn: boolean | undefined;
// }

export interface StoreState {
	selectedContact: PipelineLead | undefined;
	isLoggedIn: boolean | undefined;
	userId: string | undefined;
	menuLoaded: boolean | undefined;
	screenSizeInfo: ScreenSizeInfo | undefined;
	notificationsModifiedList: NotificationReceiveInfoModel[];
	notificationAdded: NotificationReceiveInfoModel | undefined;
	chatMessagesModifiedList: ChatMessage[];
	chatMessageAdded: ChatMessage | undefined;
	navbarTitleUpdated: string;
	applyMarginOnBody: boolean;
	menuGroupUpdated: MenuGroupModel[];
	recentMenuGroupUpdated: MenuItemModel[];
	favoriteMenuGroupUpdated: MenuItemModel[];
	breadcrumb: Breadcrumb[];
	isWikiModuleDataUpdated: boolean;
	showFavoriteIconOnBreadcrumb: boolean;
	userPermissionList: InternalTitlePermissionListItem[] | undefined;
	breadCrumbFavoriteMenu: Breadcrumb[];
	breadcrumbActions: BreadcrumbIconsAction | undefined;
	hasUnSavedChanges: boolean;
}

export interface AppState {
	store: StoreState;
}

export class TaskConversion {
	id!: string;
	parentId!: number;
	title!: string;
	start!: Date;
	end!: Date;
	progress!: number;

	constructor(actionStepGUID: string, parentActionStepGUID: any, step: any, startDate: Date, endDate: Date, completedPercentage: number) {
		this.id = actionStepGUID;
		this.parentId = parentActionStepGUID;
		this.title = step;
		this.start = startDate;
		this.end = endDate;
		this.progress = completedPercentage;
	}
}

export class ComponentHeightInPixel {
	public static readonly topNavbarHeight: number = 55;
	public static readonly breadcrumbHeight: number = 50;
	public static readonly footerHeight: number = 30;
	public static readonly paddingHeight: number = 40;
}

import { createSelector } from '@ngrx/store';
import { AppState, StoreState } from '../models/classes';

export const getStoreState = (state: AppState) => state.store;
export const selectAuthState = createSelector(getStoreState, (state: StoreState) => state.isLoggedIn);

export const userIdState = createSelector(getStoreState, (state: StoreState) => state.userId);

export const menuLoadedState = createSelector(getStoreState, (state: StoreState) => state.menuLoaded);

export const screenSizeChangedState = createSelector(getStoreState, (state: StoreState) => state.screenSizeInfo);

export const notificationCollectionUpdateState = createSelector(getStoreState, (state: StoreState) => state.notificationsModifiedList);

export const notificationAddedState = createSelector(getStoreState, (state: StoreState) => state.notificationAdded);

export const chatCollectionUpdateState = createSelector(getStoreState, (state: StoreState) => state.chatMessagesModifiedList);

export const chatAddedState = createSelector(getStoreState, (state: StoreState) => state.chatMessageAdded);

export const navbarTitleUpdated = createSelector(getStoreState, (state: StoreState) => state.navbarTitleUpdated);

export const applyMarginOnBody = createSelector(getStoreState, (state: StoreState) => state.applyMarginOnBody);

export const menuGroupUpdated = createSelector(getStoreState, (state: StoreState) => state.menuGroupUpdated);

export const recentMenGroupUpdated = createSelector(getStoreState, (state: StoreState) => state.recentMenuGroupUpdated);

export const favoriteMenuUpdated = createSelector(getStoreState, (state: StoreState) => state.favoriteMenuGroupUpdated);

export const breadcrumbState = createSelector(getStoreState, (state: StoreState) => state.breadcrumb);

export const wikiModuleDataUpdated = createSelector(getStoreState, (state: StoreState) => state.isWikiModuleDataUpdated);

export const favoriteIconOnBreadcrumbState = createSelector(getStoreState, (state: StoreState) => state.showFavoriteIconOnBreadcrumb);

export const userPermissionLoaded = createSelector(getStoreState, (state: StoreState) => state.userPermissionList);

export const breadcrumbIconsActionState = createSelector(getStoreState, (state: StoreState) => state.breadcrumbActions);

export const breadCrumbMenuFavoriteState = createSelector(getStoreState, (state: StoreState) => state.breadCrumbFavoriteMenu);

export const dirtySaveActionState = createSelector(getStoreState, (state: StoreState) => state.hasUnSavedChanges);

import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { Subject, lastValueFrom } from 'rxjs';
import { ResultHelper } from '../../../../../goldstar-share/src/app/common/result-extension';
import { Result } from '../../../../../goldstar-share/src/app/models/models';
import {
	DeleteRequest,
	EntityGetRequest,
	FetchToDoEventRequest,
	FetchToDoRequest,
	InternalToDoEvent,
	InternalToDoEventModel,
	InternalToDoEventTypeModel,
	InternalToDoStatusModel,
	ToDoEventRequest,
	ToDoEventSnoozeHistory,
	TodoTemplate,
} from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { ToDoActionModelData, ToDoAuditDetailsModel, ToDoEventModel } from '../components/stand-alone/admin/home/to-do-list/shared/to-do-list.models';
import moment from 'moment';
import { EventCategory, TodoListEventStates, TodoListEventStatus } from '../components/stand-alone/admin/home/to-do-list/shared/enum';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../components/auth/auth.service';
import { PermissionService } from '../../../../../goldstar-share/src/app/services/permission.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UserInfoService } from '../../services/user-info.service';
import { CommonService } from '../../../../../goldstar-share/src/app/services/common.service';

@Injectable({
	providedIn: 'root',
})
export class ToDoService {
	todoActionModelData = new Subject<ToDoActionModelData>();
	updatedToDoItem = new Subject<InternalToDoEventModel>();
	public toDoEventData: InternalToDoEventModel[] = [];
	methodInitialized: boolean = false;
	toDoStatusList: InternalToDoStatusModel[] = [];
	todoAuditDetailsModelData = new Subject<ToDoAuditDetailsModel>();
	public selectedToDoItem!: InternalToDoEventModel;
	public groupedToDoModelList: ToDoEventModel[] = [];

	constructor(private apiV2: ApiService, public datePipe: DatePipe, private permissionService: PermissionService, private commonService: CommonService) {}

	async checkIfUserHasPermission(): Promise<Result<any>> {
		let promise: Promise<boolean>[] = [];
		promise.push(this.permissionService.userHasPermission('/dashboard/view-to-do-event'));
		promise.push(this.permissionService.userHasPermission('/dashboard/create-to-do-event'));
		const promiseList = await Promise.all(promise);
		return ResultHelper.successResponse(promiseList);
	}

	async initialize(loggedInUserId: string): Promise<Result<any>> {
		try {
			let request: EntityGetRequest = {
				pageNumber: 1,
				pageSize: 1000,
				searchFilter: [],
			};

			const fetchUserToDoRequest: FetchToDoRequest = {
				entityGetRequest: request,
				userId: loggedInUserId,
			};
			let response = await this.fetchInternalToDoEventData(fetchUserToDoRequest);
			if (response.isSuccess) return ResultHelper.successResponse([]);
			else return ResultHelper.failedResponse(response.message ?? '');
		} catch (error: any) {
			return ResultHelper.failedResponse(error);
		}
	}

	async updateInternalToDoData(userId: string): Promise<boolean> {
		let request: EntityGetRequest = {
			pageNumber: 1,
			pageSize: 1000,
			searchFilter: [],
		};

		const fetchUserToDoRequest: FetchToDoRequest = {
			entityGetRequest: request,
			userId: userId,
		};

		let response = await lastValueFrom(this.apiV2.getInternalToDoEventData({ body: fetchUserToDoRequest }));
		if (response && response.isSuccess && response.data?.items) this.toDoEventData = response.data.items;
		this.groupedToDoModelList = await this.prepareToDoListData();
		return true;
	}

	async prepareToDoListData() {
		const tomorrowDate = moment().add(1, 'days').startOf('day');

		let toDoList: InternalToDoEventModel[] = this.toDoEventData.filter((x) => x.eventStatusSystemCode?.toLowerCase().trim() == TodoListEventStatus.SCHEDULED.toLowerCase().trim());

		toDoList.sort((a, b) => {
			const aSnoozeDate = a.snoozeHistoryDate ?? a.dueDate;
			const bSnoozeDate = b.snoozeHistoryDate ?? b.dueDate;
			return new Date(aSnoozeDate ?? '').getTime() - new Date(bSnoozeDate ?? '').getTime();
		});

		toDoList = toDoList.map((itm) => {
			let state = this.getToDoEventState(
				itm.eventStatusSystemCode ?? '',
				this.datePipe.transform(itm.dueDate) ?? '',
				this.datePipe.transform(itm.snoozeHistoryDate) ?? '',
				itm.isSnoozeAllowed ?? false
			);

			if (state === TodoListEventStates.DUETODAY) {
				state = TodoListEventStates.TODAY;
			} else if (state === TodoListEventStates.SNOOZED || state === TodoListEventStates.UPCOMING) {
				let date = itm.snoozeHistoryDate && itm.isSnoozeAllowed ? moment(itm.snoozeHistoryDate).startOf('day') : moment(itm.dueDate).startOf('day');
				if (tomorrowDate.isSame(date)) state = TodoListEventStates.TOMORROW;
				else state = TodoListEventStates.UPCOMING;
			}
			let eventCategory = this.getToDoEventCategory(this.datePipe.transform(itm.dueDate) ?? '', this.datePipe.transform(itm.snoozeHistoryDate), itm.isSnoozeAllowed ?? false);

			return {
				...itm,
				eventState: state,
				eventCategory: eventCategory,
			};
		});

		toDoList = toDoList.sort((a, b) => (a.eventState ?? '').localeCompare(b.eventState ?? ''));

		const groupedList: { [key: string]: InternalToDoEventModel[] } = {};

		toDoList.forEach((itm) => {
			if (itm.eventCategory) {
				if (!groupedList[itm.eventCategory]) {
					groupedList[itm.eventCategory] = [];
				}
				groupedList[itm.eventCategory].push(itm);
			}
		});

		return Object.keys(groupedList).map((key) => {
			return {
				eventCategory: key,
				items: groupedList[key],
			};
		});
	}

	updateToDoList(updatedItem: InternalToDoEvent) {
		const index = this.toDoEventData.findIndex((x) => x.internalToDoEventGUID === updatedItem.internalToDoEventGUID);
		if (index > -1) {
			this.toDoEventData[index] = updatedItem;
			return true;
		}
		return false;
	}

	public async fetchInternalToDoEventData(userToDoRequest: FetchToDoRequest): Promise<Result<InternalToDoEventModel[]>> {
		return await lastValueFrom(this.apiV2.getInternalToDoEventData({ body: userToDoRequest })).then((response) => {
			if (response.isSuccess) {
				if (response.data?.items) this.toDoEventData = response.data?.items;
				return ResultHelper.successResponse(response.data?.items ?? []);
			} else {
				return ResultHelper.failedResponse<InternalToDoEventModel[]>('Failed to fetch to do list');
			}
		});
	}

	public async fetchAllInternalToDoStatusData(): Promise<Result<InternalToDoStatusModel[]>> {
		if (this.toDoStatusList.length == 0) {
			return await lastValueFrom(this.apiV2.getAllToDoStatus()).then((response) => {
				if (response.isSuccess) {
					this.toDoStatusList = response.data?.items ?? [];
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<InternalToDoStatusModel[]>('Failed to fetch to status data');
				}
			});
		} else return ResultHelper.successResponse(this.toDoStatusList);
	}

	showActionModal(data: ToDoActionModelData) {
		this.todoActionModelData.next(data);
	}

	showAuditDetailsModal(data: ToDoAuditDetailsModel) {
		this.todoAuditDetailsModelData.next(data);
	}

	async updateToDoActionData(updatedItem: InternalToDoEventModel) {
		try {
			let response = await lastValueFrom(this.apiV2.updateToDoEventData({ body: updatedItem }));
			if (response != null && response.isSuccess) {
				return true;
			}
			return false;
		} catch (e) {
			console.log(e);
			return false;
		}
	}

	public async fetchAllEventTypes(): Promise<Result<InternalToDoEventTypeModel[]>> {
		return await lastValueFrom(this.apiV2.getAllToDoEventTypes()).then((response) => {
			if (response.isSuccess) {
				return ResultHelper.successResponse(response.data?.items ?? []);
			} else {
				return ResultHelper.failedResponse<InternalToDoEventTypeModel[]>('Failed to fetch to do list');
			}
		});
	}

	public async saveToDoEvent(toDoEvent: ToDoEventRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.toDoEventAdd({ body: toDoEvent }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}
	public getToDoEventState(toDoStatus: string, toDoEventDueDate: string, snoozedDate: string, isSnoozed: boolean) {
		let todaysDate = moment().startOf('day');
		let dueDate = moment(toDoEventDueDate).startOf('day');
		if (toDoStatus == TodoListEventStatus.COMPLETED || toDoStatus == TodoListEventStatus.CANCELLED) {
			return toDoStatus;
		}
		if (isSnoozed && snoozedDate) {
			let snoozeDate = moment(snoozedDate).startOf('day');
			if (todaysDate.isBefore(snoozeDate)) {
				return TodoListEventStates.SNOOZED;
			} else if (todaysDate.isSame(snoozeDate)) {
				return TodoListEventStates.DUETODAY;
			} else if (todaysDate.isAfter(snoozeDate)) {
				return TodoListEventStates.OVERDUE;
			}
		} else if (todaysDate.isSame(dueDate)) {
			return TodoListEventStates.DUETODAY;
		} else if (todaysDate.isAfter(dueDate)) {
			return TodoListEventStates.OVERDUE;
		} else if (todaysDate.isBefore(dueDate)) {
			return TodoListEventStates.UPCOMING;
		}
		return '';
	}
	public async loadToDoEventDetails(toDoEventGUID: string): Promise<Result<InternalToDoEvent>> {
		let toDoEventRequest: FetchToDoEventRequest = {
			internalToDoListGUID: toDoEventGUID,
		};
		return await lastValueFrom(
			this.apiV2.getToDoDetails({
				body: toDoEventRequest,
			})
		).then((response) => {
			if (response.isSuccess && response.data && response.data.items) {
				return ResultHelper.successResponse<InternalToDoEvent>(response.data.items[0]);
			} else {
				return ResultHelper.failedResponse('Failed to fetch to do list');
			}
		});
	}

	public async addTodoSnoozeHistory(toDoEventSnoozeHistory: ToDoEventSnoozeHistory): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.addTodoSnoozeHistory({ body: toDoEventSnoozeHistory }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	public getToDoEventCategory(toDoEventDueDate: string, snoozedDate: string | null, isSnoozed: boolean) {
		let todaysDate = moment().startOf('day');

		let dueDate = isSnoozed && snoozedDate ? moment(snoozedDate).startOf('day') : moment(toDoEventDueDate).startOf('day');

		if (dueDate.isBefore(todaysDate)) {
			return EventCategory.PASTDUE;
		} else if (dueDate.isSame(todaysDate)) {
			return EventCategory.DUETODAY;
		} else {
			return EventCategory.UPCOMING;
		}
	}

	/**
	 * Fetch all Todo Templates
	 * @returns list of Todo Templates
	 */
	public async getAllTodoTemplates(): Promise<Result<TodoTemplate[]>> {
		return this.commonService.toResultData<TodoTemplate[]>(async () => {
			const request: EntityGetRequest = {
				searchFilter: [
					{
						searchOption: 'All',
					},
				],
			};
			const fetchTodoTemplatesResult = await lastValueFrom(this.apiV2.todoTemplateList({ body: request }));
			if (fetchTodoTemplatesResult.isSuccess && fetchTodoTemplatesResult.data && fetchTodoTemplatesResult.data.items) {
				return ResultHelper.successResponse<TodoTemplate[]>(fetchTodoTemplatesResult.data.items);
			}
			throw 'Failed to fetch todo templates';
		});
	}

	/**
	 * Fetches a matching Todo Template
	 * @param internalTodoTemplateGUID : filter value
	 * @returns matching Todo Template
	 */
	public async getTodoTemplate(internalTodoTemplateGUID: string): Promise<Result<TodoTemplate>> {
		return this.commonService.toResultData<TodoTemplate>(async () => {
			const request: EntityGetRequest = {
				searchFilter: [
					{
						searchColumnType: 'string',
						searchOption: '=',
						searchColumn: 'internalToDoTemplateGUID',
						searchValue: internalTodoTemplateGUID,
					},
				],
			};
			const fetchTodoTemplatesResult = await lastValueFrom(this.apiV2.todoTemplateList({ body: request }));
			if (fetchTodoTemplatesResult.isSuccess && fetchTodoTemplatesResult.data && fetchTodoTemplatesResult.data.items) {
				return ResultHelper.successResponse<TodoTemplate>(fetchTodoTemplatesResult.data.items[0]);
			}
			throw 'Failed to fetch todo templates';
		});
	}

	/**
	 * Adds or Updates a Todo Template item
	 * @param requests Todo Template item
	 * @returns added or updated item guid
	 */
	public async addOrUpdateTodoTemplate(requests: TodoTemplate): Promise<Result<string>> {
		return this.commonService.toResultData<string>(async () => {
			const addOrUpdateTodoTemplateResult = await lastValueFrom(this.apiV2.todoTemplateAddOrUpdate({ body: requests }));
			if (!addOrUpdateTodoTemplateResult.isSuccess) throw Error(' Failed to load Todo Template');
			return ResultHelper.successResponse<string>(addOrUpdateTodoTemplateResult.data ?? '');
		});
	}

	/**
	 * Delete a matching Todo Template
	 * @param request
	 */
	public async deleteTodoTemplate(request: DeleteRequest): Promise<Result<string>> {
		return this.commonService.toResultData<string>(async () => {
			const deleteTodoTemplateResult = await lastValueFrom(this.apiV2.todoTemplateDelete({ body: request }));
			if (!deleteTodoTemplateResult.isSuccess) throw Error('Failed to delete todo template');
			return ResultHelper.successResponse<string>(deleteTodoTemplateResult.data ?? '');
		});
	}

	//method for handling whitespaces
	trimValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value) {
				let trimmedValue = control.value.trim();
				if (trimmedValue === '') {
					return { required: true };
				} else {
					return null;
				}
			}
			return null;
		};
	}

	sortListByState(toDoList: any[]) {
		const stateOrder = [
			TodoListEventStates.OVERDUE,
			TodoListEventStates.DUETODAY,
			TodoListEventStates.UPCOMING,
			TodoListEventStates.SNOOZED,
			TodoListEventStates.COMPLETED,
			TodoListEventStates.CANCELLED,
		];
		if (toDoList) {
			toDoList.sort((a: any, b: any) => {
				const stateOrderA = stateOrder.indexOf(a.eventState);
				const stateOrderB = stateOrder.indexOf(b.eventState);
				return stateOrderA - stateOrderB;
			});
		}
		return toDoList;
	}
}

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerState } from '../../models/models';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { LoaderService } from './service/loader.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [NgxSpinnerModule, CommonModule, RouterModule],
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnChanges, OnDestroy {
	public spinnerObserver$!: Subscription;
	@Input() spinnerName: string = 'Spinner';
	public showSpinner!: boolean;

	constructor(private spinnerService: NgxSpinnerService, private loaderSpinner: LoaderService) {}

	ngOnInit() {
		this.initializeSubscription();
	}

	initializeSubscription() {
		this.spinnerObserver$ = this.loaderSpinner.updateSpinnerState.subscribe((spinnerState: SpinnerState) => {
			switch (spinnerState) {
				case SpinnerState.show:
					this.spinnerService.show();
					break;

				default:
				case SpinnerState.hide:
					this.spinnerService.hide();
					break;
			}
		});
	}

	ngOnDestroy() {
		//Removed since common loader is used other pages, after navigating to other pages method was getting unsubscribed due to which loader was not shown
		//this.spinnerObserver$.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {}
}

import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { storeReducer } from './store/store.reducer';
import { APIClient, API_BASE_URL, CorporateAPIClient } from '../../../goldstar-share/src/app/api-data/nswag-models';
import { environment } from '../environments/environment';
import { ApiModule as ApiModuleUpdate } from '../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/api.module';
import { DevProdInterceptor } from './interceptor/dev-prod.interceptor';
import { MultiItemHoverDirective } from './directives/multi-item-hover';
import { MenuGroupDirective } from './directives/menu-group.directive';
import { BtnHoverDirective } from './directives/btn-hover.directive';
import { StoreModule } from '@ngrx/store';
import {
	MsalBroadcastService,
	MsalGuard,
	MsalGuardConfiguration,
	MsalInterceptor,
	MsalInterceptorConfiguration,
	MsalRedirectComponent,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ElementIsActiveDirective } from './directives/element-is-active.directive';

/* For Breadcrumb */
import { TokenInterceptor } from './interceptor/token.interceptor';
import { UserGroupDataStore } from './services/user-group/user-group-data.store';
import { UserGroupService } from './services/user-group/user-group.service';
import { FluidContainerContentHeightDirective } from './directives/fluid-container-content-height.directive';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { InternalPermissionService } from './services/internal-permission.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';
import { MatTooltipModule } from '@angular/material/tooltip';
export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.azureAuthClientId,
			authority: environment.azureAuthority,
			redirectUri: environment.azureRedirectUri,
			postLogoutRedirectUri: window.location.protocol + '//' + window.location.host + '/logout',
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
		},
		system: {
			allowNativeBroker: false,
			// loggerOptions: {
			// 	loggerCallback,
			// 	logLevel: LogLevel.Info,
			// 	piiLoggingEnabled: false,
			// },
		},
	});
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ['user.read'],
		},
	};
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

@NgModule({
	declarations: [AppComponent, MultiItemHoverDirective, MenuGroupDirective, BtnHoverDirective, ElementIsActiveDirective, FluidContainerContentHeightDirective],
	imports: [
		HttpClientModule,
		BrowserModule,
		MatTooltipModule,
		CommonModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		MatDialogModule,
		StoreModule.forRoot({
			store: storeReducer,
		}),
		ApiModuleUpdate.forRoot({ rootUrl: environment.nodeApiRoot }),
		ToastrModule.forRoot(),
		AppRoutingModule,
		QuillModule.forRoot(),
	],
	providers: [
		{ provide: API_BASE_URL, useValue: environment.apiRoot },
		APIClient,
		CorporateAPIClient,
		{ provide: 'environment', useValue: environment },
		{ provide: HTTP_INTERCEPTORS, useClass: DevProdInterceptor, multi: true },
		{ provide: Window, useValue: window },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
		TitleCasePipe,
		UserGroupDataStore,
		UserGroupService,
		provideEnvironmentNgxMask(),
		InternalPermissionService,
		DatePipe,
	],
	bootstrap: [AppComponent, MsalRedirectComponent],
	exports: [],
})
export class AppModule {
	constructor() {}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-base-internal',
	templateUrl: './base-internal.component.html',
	styleUrls: ['./base-internal.component.scss'],
})
export class BaseInternalComponent implements OnInit, OnChanges {
	// Prepares the body layout with or without header image
	@Input() headerImage: boolean = false;

	constructor() {}
	ngOnChanges(changes: SimpleChanges): void {
		this.headerImage = false;
		this.headerImage = true;
	}

	public updateHeaderTitle(title: string) {}

	ngOnInit(): void {}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RoutePathConfig } from '../models/models';
import { MenuService } from '../../services/menuService';
import { MenuGroupModel, MenuItemModel } from '../../models/models';
import { UserRecentService } from '../../services/user-recent.service';
import { PermissionService } from '../../../../../goldstar-share/src/app/services/permission.service';
import { AuthService } from '../../components/auth/auth.service';

@Injectable({
	providedIn: 'root',
})
export class RoutePathResolverService {
	// This is a hard coded string for profile component
	private defaultRoutePath: RoutePathConfig = {
		rooted: false,
		component: 'HomeComponent',
	};
	public routedPath$: BehaviorSubject<RoutePathConfig> = new BehaviorSubject(this.defaultRoutePath);

	constructor(private menuService: MenuService, private userRecentService: UserRecentService, private permissionService: PermissionService, private authService: AuthService) {}

	getRoutedPath(): BehaviorSubject<RoutePathConfig> {
		return this.routedPath$;
	}

	resolveHomeRoutePath() {
		let routeConfig: RoutePathConfig = {
			rooted: false,
			component: 'HomeComponent',
		};
		this.routedPath$.next(routeConfig);
	}

	resolvePageNotFoundPath() {
		let routeConfig: RoutePathConfig = {
			rooted: false,
			component: 'PageNotFoundComponent',
		};
		this.routedPath$.next(routeConfig);
	}

	async resolveRouterPathFromGUID(pathIsRouted: boolean, fullQualifiedRoutePath?: string) {
		if (!fullQualifiedRoutePath) return;
		const fullQualifiedRoute = fullQualifiedRoutePath.split('?')[0];
		const urlParts = fullQualifiedRoute.split('/');
		if (urlParts) {
			let urlGUID = urlParts[urlParts.length - 1];

			if (!this.menuService.menuGroupCollection || !MenuService.menuRouteCollection) {
				await this.menuService.initialize();
			}

			let componentName = MenuService.menuRouteCollection[urlGUID];
			let matchingMenuItem = this.matchingMenuItem(urlGUID);

			const userHasPermission = await this.permissionService.userPermissionCover(urlGUID, this.authService.emailAddress);
			if (!userHasPermission) return this.resolvePageNotFoundPath();

			await this.menuService.buildBreadcrumb(urlGUID);

			//console.log('Menu path is rooted', matchingMenuItem?.isActive);
			if (matchingMenuItem?.isActive) {
				this.userRecentService.addUserRecentMenu(matchingMenuItem);
			}

			let routeConfig: RoutePathConfig = {
				rooted: pathIsRouted,
				component: componentName,
			};
			this.routedPath$.next(routeConfig);
		}
	}

	resolveRouterUrlGUIDFromComponentName(fullQualifiedComponentName?: string): string | undefined {
		if (!fullQualifiedComponentName || !MenuService.menuRouteCollection) return;
		const allKeys = Object.keys(MenuService.menuRouteCollection);
		const matchingKey = allKeys.find((key) => {
			const componentValue = MenuService.menuRouteCollection[key];
			if (componentValue === fullQualifiedComponentName) return key;
			return undefined;
		});
		return matchingKey;
	}

	matchingMenuItem(urlGUID: string): MenuItemModel | undefined {
		let menuItem: MenuItemModel | undefined = undefined;
		const menuGroups = this.menuService.menuGroupCollection;
		menuGroups.forEach((menuGroup: MenuGroupModel) => {
			const matchingMenuItem = menuGroup.menuItems.find((x) => x.menuGUID === urlGUID);
			if (matchingMenuItem) {
				menuItem = matchingMenuItem;
			}
		});
		return menuItem;
	}
}

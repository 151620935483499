import { Injectable } from '@angular/core';
import { MenuService } from './menuService';
import { MenuGroupModel, MenuItemModel } from '../models/models';
import { cloneDeep } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '../models/classes';
import { RecentMenuUpdateAction } from '../store/store.actions';

@Injectable({
	providedIn: 'root',
})
export class UserRecentService {
	constructor(private menuService: MenuService, private store: Store<AppState>) {}

	async fetchAllUserRecentMenu(): Promise<MenuItemModel[]> {
		let allMenus = await this.menuService.loadAllMenus();
		if (!allMenus) return [];

		let recentMenus: MenuItemModel[] = [];
		allMenus.forEach((menuGroup: MenuGroupModel) => {
			menuGroup.menuItems
				.filter((x) => x.isRecent)
				.sort((n1: MenuItemModel, n2: MenuItemModel) => {
					if (!n1 || !n1.updateDate || !n2 || !n2.updateDate) return 0;
					if (n1.updateDate > n2.updateDate) {
						return 1;
					}
					if (n1.updateDate < n2.updateDate) {
						return -1;
					}
					return 0;
				})
				.forEach((recentMenuItem: MenuItemModel) => {
					recentMenus.push(recentMenuItem);
				});
		});

		// Sorting in descending order
		recentMenus = recentMenus.sort((a, b) => (a.updateDate > b.updateDate ? -1 : 1));
		return recentMenus;
	}

	// ADD A USER RECENT MENU ITEM
	async addUserRecentMenu(menuItemModel: MenuItemModel) {
		this.menuService.menuGroupCollection.forEach((x) => {
			let matchingItem = x.menuItems.find((y) => y.menuGUID === menuItemModel.menuGUID);
			if (matchingItem) {
				matchingItem.isRecent = true;
				matchingItem.updateDate = new Date();
			}
		});
		this.menuService.addOrUpdateRecentMenu(menuItemModel);

		// Refresh the favorite menus
		let clonedFavoriteMenus = cloneDeep(this.menuService.recentMenuCollection);
		this.store.dispatch(RecentMenuUpdateAction({ menuList: clonedFavoriteMenus }));
	}
}

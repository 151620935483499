export enum FeedbackOptions {
	QUESTION = 'Question',
	COMMENT = 'Comment',
	SUGGESTION = 'Suggestion',
}

export enum FeedbackPlaceholder {
	PH_QUESTION = 'Enter your question',
	PH_COMMENT = 'Enter your comment',
	PH_SUGGESTION = 'Enter your suggestion',
}

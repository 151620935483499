import { Injectable } from '@angular/core';
import { LocalStorageRefService } from './local.storage.ref.service';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService implements ILocalStorageService {
	private _localStorage: Storage;
	constructor(localStorageRefService: LocalStorageRefService) {
		this._localStorage = localStorageRefService.localStorage;
	}

	/**
	 * Retrieves all the keys of the local storage
	 */
	allKeys(): string[] {
		let allKeys: string[] = [];
		if (this._localStorage) {
			allKeys = Object.keys(this._localStorage);
		}
		return allKeys;
	}

	/**
	 * Set's the value in the local storage, if an item already exist based on the replaceItem flag, the item is replaced
	 * @param key : The key with which the item is store in local storage
	 * @param data : The data to be stored in local storage
	 * @param replaceItem : Flag to specify item replacement on conflict.
	 */
	setItem<T>(key: string, data: T, replaceItem: boolean = true) {
		const jsonData = JSON.stringify(data);
		const itemAlreadyExists = this.itemExists(key);
		if (itemAlreadyExists && replaceItem) {
			this._localStorage.setItem(key, jsonData);
			return;
		}
		this._localStorage.setItem(key, jsonData);
	}

	itemExists(key: string): boolean {
		return this._localStorage.getItem(key) != null;
	}

	tryGetItem<T>(key: string): T | null {
		const value = this._localStorage.getItem(key);
		if (value) {
			const data = JSON.parse(value);
			return data as T;
		}
		return null;
	}

	removeItem(key: string) {
		this._localStorage.removeItem(key);
	}

	reset() {
		this._localStorage.clear();
	}
}

export interface ILocalStorageService {
	setItem<T>(key: string, data: T, replaceItem: true): void;
	itemExists(key: string): boolean;
	tryGetItem<T>(key: string): T | null;
	removeItem(key: string): void;
	allKeys(): string[];
	reset(): void;
}

export enum TodoListEventStatus {
	SCHEDULED = 'Scheduled',
	COMPLETED = 'Completed',
	CANCELLED = 'Cancelled',
}

export enum TodoListEventStates {
	OVERDUE = 'Overdue',
	DUETODAY = 'Due Today',
	UPCOMING = 'Upcoming',
	SNOOZED = 'Snoozed',
	CANCELLED = 'Cancelled',
	COMPLETED = 'Completed',
	TODAY = 'Today',
	TOMORROW = 'Tomorrow',
}

export enum EventCategory {
	PASTDUE = 'Past Due',
	UPCOMING = 'Upcoming',
	DUETODAY = 'Due Today',
}

import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, SilentRequest } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { VerificationCodeType } from '../../../../../goldstar-share/src/app/api-data/data-user-authentication.service';
import { APIClient, ResultOfSendUserVerificationCodeResponse, ResultOfVerifyCodeResponse } from '../../../../../goldstar-share/src/app/api-data/nswag-models';
import { BehaviorSubject, lastValueFrom, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { StoreState } from '../../models/classes';
import { LoginStateAction } from '../../store/store.actions';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { LocalStorageService } from '../../../../../goldstar-share/src/app/services/local.storage.service';
import { SessionService } from '../../services/session.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { InternalAppStorageService } from '../../../../../goldstar-share/src/app/services/internal-app-storage.service';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public sessionGUID: string | null = null;
	private readonly appTokenKey = 'internalAPPTokenExpiryValue';
	private readonly appToken = 'internalAppToken';
	public decodedJWTToken!: JwtPayload;
	public userNameObservable$: BehaviorSubject<string> = new BehaviorSubject('');
	public isLoggedIn: boolean = false;
	public isVerified: boolean = false;
	public validationToken: string = '';
	public phoneNumber: string = '';
	public emailAddress: string = '';
	public userId: string = '';
	public accountInfo!: AccountInfo;
	public fetchTokenRequest!: SilentRequest;
	public state!: string;
	public isaddingUser: boolean = false;
	public isActiveImpersonation: boolean = false;
	public loggedInUser!: string;
	private accessToken!: string;

	constructor(
		private router: Router,
		private apiClient: APIClient,
		private store: Store<StoreState>,
		private localStorageService: LocalStorageService,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private sessionService: SessionService,
		private storageService: InternalAppStorageService,
		private tokenService: TokenService,
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
	) {
		const accounts = this.authService.instance.getAllAccounts();
		if (accounts?.length) {
			this.accountInfo = accounts[0];
			this.isLoggedIn = true;
			this.userId = this.accountInfo.username;
			this.emailAddress = this.accountInfo.username;

			this.fetchTokenRequest = {
				scopes: ['user.read'],
				account: this.accountInfo,
			};

			console.log('========== TRYING TO ACQUIRE THE TOKEN FROM CACHE ==================');
			this.tokenService.acquireToken().subscribe((token) => {
				sessionService.generateSession(this.userId).then((response) => {
					this.store.dispatch(LoginStateAction({ isLoggedIn: true, userId: this.userId }));
				});
			});
		}

		this.msalBroadcastService.msalSubject$
			.pipe(filter((msg: EventMessage) => [EventType.LOGIN_SUCCESS, EventType.LOGIN_FAILURE, EventType.LOGOUT_SUCCESS, EventType.LOGOUT_START].some((x) => x === msg.eventType)))
			.subscribe((result: EventMessage) => {
				this.isLoggedIn = result.eventType === EventType.LOGIN_SUCCESS;

				if (this.isLoggedIn) {
					console.log(result);
					const payload = result.payload as AuthenticationResult;
					this.authService.instance.setActiveAccount(payload.account);
					this.accessToken = payload.accessToken;
					this.decodedJWTToken = jwtDecode(this.accessToken)!;
					this.storageService.storeItem<number>(this.appTokenKey, this.decodedJWTToken.exp ?? 0);
					this.storageService.storeItem<string>(this.appToken, this.accessToken);

					const accounts = this.authService.instance.getAllAccounts();
					if (accounts?.length) {
						this.accountInfo = accounts[0];
						this.isLoggedIn = true;
						this.userId = this.accountInfo.username;
						this.emailAddress = this.accountInfo.username;
						if (!sessionService.sessionGUID) sessionService.generateSession(this.userId);
					}
				}

				this.store.dispatch(LoginStateAction({ isLoggedIn: true, userId: this.userId }));
			});
	}

	updateUserName(userName: string) {
		this.userNameObservable$.next(userName);
	}

	// ToDo: to be used for global impersonation setting;
	/* this is for updating user id while impersonation */
	async startImpersonation(userId: string) {
		await this.sessionService.generateSession(this.userId, userId);
		this.loggedInUser = this.userId;
		this.userId = userId;
		this.isActiveImpersonation = true;
		this.store.dispatch(LoginStateAction({ isLoggedIn: true, userId: userId }));
	}

	/* this is for stopping active user impersonation */
	async stopImpersonation(userId: string) {
		this.userId = this.loggedInUser;
		await this.sessionService.generateSession(this.userId);
		this.isActiveImpersonation = false;
		this.store.dispatch(LoginStateAction({ isLoggedIn: true, userId: this.userId }));
	}

	/**
	 * logout the user and navigate to logout page post successful logout
	 */
	logout(): void {
		this.authService.logout().subscribe(() => {
			this.isLoggedIn = false;
			this.router.navigate(['/logout']);
			this.clearUserToken();
		});
	}

	login(state: string | undefined) {
		try {
			const redirectRequest: any = {
				...this.msalGuardConfig.authRequest,
				state: state,
			};
			this.authService.loginRedirect(redirectRequest);
		} catch (err) {}
	}

	async sendVerificationCheck(phone: string, verificationCodeType: VerificationCodeType): Promise<ResultOfSendUserVerificationCodeResponse> {
		try {
			let verificationResponse = await lastValueFrom(
				this.apiClient.sendUserVerificationCode({
					phone: `${phone}`,
					verificationCodeType: verificationCodeType,
				})
			);

			console.log(verificationResponse);

			if (!verificationResponse.isSuccess && verificationResponse.errorMessage === 'GONNA GET SOME MESSAGE HERE! BRAD HAS TO FIX THE SUSPENSION') {
				verificationResponse = await lastValueFrom(
					this.apiClient.sendUserVerificationCode({
						phone: `${phone}`,
						verificationCodeType: 'call',
					})
				);
			}

			return verificationResponse;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async verifyVerificationCheck(serviceSid: string, phone: string, verificationCode: string /*, email: string*/): Promise<ResultOfVerifyCodeResponse> {
		try {
			const result: ResultOfVerifyCodeResponse = await lastValueFrom(
				this.apiClient.verifyCode({
					code: verificationCode,
					phone: phone,
					serviceSid: serviceSid,
				})
			);
			this.isVerified = result.data?.verifySuccessful ?? false;
			this.validationToken = result.data?.validationToken ?? '';
			this.phoneNumber = result.data?.phone ?? '';
			return result;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	/**
	 * Clear all token cache for a given user
	 */
	clearUserToken() {
		this.sessionService.endSession();
		const allStorageKeys = this.localStorageService.allKeys();
		const allTokenStorageKeys = allStorageKeys.filter((key) => key.includes('msal') || key.includes('login.windows.net'));
		console.log('The keys of the local storage is ----', allTokenStorageKeys);
		if (allTokenStorageKeys.length > 0) {
			allTokenStorageKeys.forEach((key: string) => {
				if (this.localStorageService.itemExists(key)) {
					this.localStorageService.removeItem(key);
				}
			});
		}
	}

	//method for handling whitespaces
	trimValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value) {
				let trimmedValue = control.value.trim();
				if (trimmedValue === '') {
					return { required: true };
				} else {
					return null;
				}
			}
			return null;
		};
	}
}

export enum LoginStatus {
	loggedIn = 'loggedIn',
	notAuthorized = 'notAuthorized',
}

interface IVerifiedUserData {
	email: string;
	phone: string;
}

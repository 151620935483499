import { Injectable } from '@angular/core';
import { LocalStorageService } from './local.storage.service';

@Injectable({
	providedIn: 'root',
})
export class InternalAppStorageService {
	/// This is a static application storage ID which is used to store all local storage keys for goldstar application.
	private readonly _applicationStorageID: string = 'goldstar-employee-portal';

	private readonly KEY!: string;
	protected SUBKEY!: string;

	constructor(private localStorageService: LocalStorageService) {}

	public storeItem<T>(key: string, data: T) {
		const operationKey = this.getOperationKey(key);
		this.localStorageService.setItem<T>(operationKey, data);
	}

	public tryLoadItem<T>(key: string): T | null {
		const operationKey = this.getOperationKey(key);
		const item = this.localStorageService.tryGetItem<T>(operationKey);
		return item;
	}

	removeItem(key: string) {
		const operationKey = this.getOperationKey(key);
		this.localStorageService.removeItem(operationKey);
	}

	protected getOperationKey(key: string) {
		const operationKey = `${this._applicationStorageID}.${key}`;
		return operationKey;
	}
}

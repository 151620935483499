import { Injectable } from '@angular/core';
import { ConfirmationPopUpModel } from '../../models/interfaces';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ConfirmationPopupService {
	confirmationModal = new Subject<ConfirmationPopUpModel>();
	constructor() {}
	showConfirmationModal(action: ConfirmationPopUpModel) {
		this.confirmationModal.next(action);
	}
}

export class CollectionHelper {
	static filterUndefinedValues<T>(collection: any[]): T[] {
		return collection.filter((item): item is T => !!item);
	}

	static removeDuplicates<T>(collection: any[], propIdentifier: string): T[] {
		const propertyValues = collection.map((obj: any) => obj[propIdentifier]);
		const uniqueValuesSet = new Set(propertyValues);
		const distinctValues = Array.from(uniqueValuesSet);
		const filteredCollection = distinctValues.map((value: any) => {
			const matchingValue = collection.filter((x) => x[propIdentifier] == value);
			return matchingValue[0];
		});
		return filteredCollection;
	}

	static selectMany<TIn, TOut>(input: TIn[], selectListFn: (t: TIn) => TOut[]): TOut[] {
		return input.reduce((out, inx) => {
			out.push(...selectListFn(inx));
			return out;
		}, new Array<TOut>());
	}

	static sort<T>(collection: any[], property: string): T[] {
		return collection.sort((val1: any, val2: any) => {
			if (val1[property].toLowerCase() > val2[property].toLowerCase()) {
				return 0;
			}
			return 1;
		});
	}

	/**
	 * Returns the element with highest value of the given comparable prop
	 * @param collection : Collection to be filtered
	 * @param property : order by prop
	 * @returns
	 */
	static max<T>(collection: any[], property: string): T {
		const max = collection.reduce((prev, current) => (prev && prev[property] > current[property] ? prev : current), 0);
		return max as T;
	}
}

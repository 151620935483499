<div class="tippy-favorite-container">
	<div class="dialog-header">
		<div class="tool-container">
			<span class="header">Favorites</span>
		</div>
		<!-- <i class="bx bx-x bx-md close-icon" (click)="close()"></i> -->
	</div>
	<ul class="favorite-container-list">
		<li class="favorite-item" *ngFor="let favoriteMenuModel of favoriteMenuModelList">
			<!-- LINK FOR EXTERNAL MENU ITEM -->
			<a *ngIf="favoriteMenuModel.isExternal" class="favorite" (click)="close()" target="_blank" [href]="favoriteMenuModel.externalLink">{{ favoriteMenuModel.name }}</a>

			<!-- LINK FOR APPLICATION MENU ITEM -->
			<a
				*ngIf="!favoriteMenuModel.isExternal"
				class="favorite"
				(click)="close()"
				[routerLink]="['', favoriteMenuModel.isWikiFavorite ? favoriteMenuModel?.menuItem?.parentMenuGUID : favoriteMenuModel.menuItemURL]"
				[queryParams]="favoriteMenuModel.queryParams"
				>{{ favoriteMenuModel.name }}</a
			>
		</li>
	</ul>
</div>

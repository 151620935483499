import { Injectable } from '@angular/core';
import { DeleteRequest, UserGroupAddRequest, UserGroupFetchRequest, UserGroupItem, UserGroupUpdateRequest } from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { ApiService } from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { Result } from '../../models/models';
import { lastValueFrom } from 'rxjs';
import { ResultHelper } from '../../../../../goldstar-client/src/app/modules/debit-card-application/common/result-extension';

@Injectable({
	providedIn: 'root',
})
export class UserGroupService {
	constructor(private apiV2: ApiService) {}

	async addUserGroup(data: UserGroupAddRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.userGroupAdd({ body: data }))
			.then((response) => {
				if (response.isSuccess) {
					const data = response.data;
					return ResultHelper.successResponse<string>(data ?? '');
				}
				throw Error('Failed to fetch data');
			})
			.catch((error: any) => {
				return ResultHelper.failedResponse(error);
			});
	}

	async updateUserGroup(data: UserGroupUpdateRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.userGroupUpdate({ body: data }))
			.then((response) => {
				if (response.isSuccess) {
					const data = response.data;
					return ResultHelper.successResponse<string>(data ?? '');
				}
				throw Error('Failed to fetch data');
			})
			.catch((error: any) => {
				return ResultHelper.failedResponse(error);
			});
	}

	async userGroupList(data: UserGroupFetchRequest): Promise<Result<UserGroupItem[]>> {
		return await lastValueFrom(this.apiV2.userGroupList({ body: data }))
			.then((response) => {
				if (response.isSuccess && response.data) {
					return ResultHelper.successResponse(response.data.items);
				}
				throw Error('Failed to fetch data');
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	async deleteUserGroup(data: DeleteRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.userGroupDelete({ body: data }))
			.then((response) => {
				if (response.isSuccess && response.data) {
					return ResultHelper.successResponse('Successfully delete user group');
				}
				throw Error('Failed to delete user group');
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}
}

import { Result } from '../../../../goldstar-share/src/app/models/models';

export class ResultHelper {
	static failedResponse<T>(message: string): Result<T> {
		const failedResponse: Result<T> = {
			isSuccess: false,
			message: message,
		};
		return failedResponse;
	}

	static successResponse<T>(data: T, message?: string): Result<T> {
		const successResponse: Result<T> = {
			isSuccess: true,
			data: data,
			message: message,
		};
		return successResponse;
	}
}

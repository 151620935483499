<div class="myDay">
	<div class="myDay-box dropdown-menu show" aria-labelledby="myDayDropdown" data-bs-popper="none">
		<div class="my-day-header">
			<h4>
				My Day<span><img (click)="close()" src="../../../../../assets/images/modal-close.png" /></span>
			</h4>
		</div>
		<div class="my-day-body" *ngIf="viewToDoEvent">
			<app-to-do-list style="display: block"></app-to-do-list>
		</div>
		<div class="my-day-footer" *ngIf="viewToDoEvent">
			<a (click)="viewAllMyDayList()">View all</a>
		</div>
	</div>
</div>

import { Action, createAction, props } from '@ngrx/store';
import {
	ApplyMarginOnBodyState,
	ChatMessageAddedState,
	ChatMessageListModifiedState,
	LoginState,
	MenuLoadedState,
	MenuGroupUpdatedAction,
	NavbarTitleUpdatedState,
	NotificationAddedState,
	NotificationListModifiedState,
	PipelineLead,
	RecentMenuUpdatedAction,
	ScreenSizeState,
	FavoriteMenuUpdatedAction,
	BreadcrumbUpdatedAction,
	WikiModuleStateUpdatedAction,
	BreadcrumbFavoriteIconToggledState,
	PermissionLoadedAction,
	BreadcrumbFavoriteMenuAction,
	BreadcrumbIconsToggleActionState,
	DirtySaveActionState,
} from '../models/interfaces';

export const selectedContactAction = createAction('Selected Pipelie Contact', props<PipelineLead>());
export const LoginStateAction = createAction('Login State Action', props<LoginState>());
export const MenuLoadedStateAction = createAction('Menu Loaded State Action', props<MenuLoadedState>());
export const ScreenSizeStateAction = createAction('Size updated', props<ScreenSizeState>());
export const NotificationsUpdatedAction = createAction('NotificationsUpdated', props<NotificationListModifiedState>());
export const NotificationAddedAction = createAction('NotificationAdded', props<NotificationAddedState>());
export const ChatMessagesUpdatedAction = createAction('ChatMessagesUpdated', props<ChatMessageListModifiedState>());
export const ChatMessageAddedAction = createAction('ChatMessageAdded', props<ChatMessageAddedState>());
export const NavbarTitleUpdatedAction = createAction('NavbarHeaderTitleUpdated', props<NavbarTitleUpdatedState>());
export const ApplyMarginOnBodyAction = createAction('ApplyMarginOnBody', props<ApplyMarginOnBodyState>());
export const MenuGroupUpdateAction = createAction('MenuGroupUpdated', props<MenuGroupUpdatedAction>());
export const RecentMenuUpdateAction = createAction('RecentMenuUpdated', props<RecentMenuUpdatedAction>());
export const FavoriteMenuUpdateAction = createAction('FavoriteMenuUpdate', props<FavoriteMenuUpdatedAction>());
export const BreadcrumbUpdateAction = createAction('BreadcrumbUpdate', props<BreadcrumbUpdatedAction>());
export const WikiModuleStateAction = createAction('wikiModuleDataUpdated', props<WikiModuleStateUpdatedAction>());
export const BreadcrumbFavoriteIconToggleAction = createAction('toggleShowFavoriteIconOnBreadcrumb', props<BreadcrumbFavoriteIconToggledState>());
export const UserPermissionLoadedAction = createAction('PermissionLoaded', props<PermissionLoadedAction>());
export const BreadcrumbIconsToggleAction = createAction('toggleIconOnBreadcrumb', props<BreadcrumbIconsToggleActionState>());
export const BreadcrumbMarkFavoriteAction = createAction('breadCrumbMarkFavoriteAction', props<BreadcrumbFavoriteMenuAction>());
export const DirtySaveHasUnsavedChangeAction = createAction('dirtySaveHasUnsavedChangeActionSaveAction', props<DirtySaveActionState>());

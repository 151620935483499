import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SpinnerState } from '../../../models/models';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	updateSpinnerState = new Subject<SpinnerState>();
	constructor() {}

	public showLoader() {
		const spinnerState: SpinnerState = SpinnerState.show;
		this.updateSpinnerState.next(spinnerState);
	}

	public hideLoader() {
		const spinnerState: SpinnerState = SpinnerState.hide;
		this.updateSpinnerState.next(spinnerState);
	}
}

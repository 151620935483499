import { Injectable } from '@angular/core';
import { ApiService } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { GetSessionRequest } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { lastValueFrom } from 'rxjs';
import { ErrorHandlingService } from '../../../../goldstar-share/src/app/services/error-handling.service';

@Injectable({
	providedIn: 'root',
})
export class SessionService {
	sessionGUID: string | null = null;

	constructor(
		private apiV2: ApiService,
		private errorHandler: ErrorHandlingService
	) {}

	async generateSession(userId: string, impersonated?: string): Promise<string> {
		try {
			const sessionRequest: GetSessionRequest = {
				internalUserId: userId,
				userAgent: navigator.userAgent,
				impersonatedId: impersonated || undefined,
			};
			const response = await lastValueFrom(this.apiV2.getSession({ body: sessionRequest }));
			if (response.isSuccess && response.data) {
				this.sessionGUID = response.data;
				return response.data;
			} else throw Error(`Couldn't generate a Session\n${response}`);
		} catch (e) {
			console.error(e);
			this.errorHandler.showErrorMessage('** Unable to establish a Session **');
			throw e;
		}
	}

	endSession() {
		if (!this.sessionGUID) console.warn('No existing session found');
		else this.sessionGUID = null;
	}
}

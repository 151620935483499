import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../models/classes';
import { dirtySaveActionState } from '../../store/store.selector';
import { ConfirmationPopupService } from '../services/confirmation-popup.service';

@Injectable({
	providedIn: 'root',
})
export class DirtySaveGuard implements CanActivate {
	hasUnsavedChanges: boolean = false;
	constructor(private store: Store<AppState>, private confirmationPopUpService: ConfirmationPopupService, private router: Router) {
		this.store.select(dirtySaveActionState).subscribe((hasUnSavedChanges) => {
			this.hasUnsavedChanges = hasUnSavedChanges ?? false;
		});
	}
	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
		const urlWithoutQueryParams = this.getUrlWithoutQueryParams(state.url);

		const queryParams = {
			queryParams: route.queryParams ? route.queryParams : undefined,
		};
		if (this.hasUnsavedChanges) {
			this.confirmationPopUpService.showConfirmationModal({
				title: '',
				message: '',
				onConfirm: () => {
					this.router.navigate([urlWithoutQueryParams], queryParams);
				},
			});
			return false;
		} else {
			return true;
		}
	}

	private getUrlWithoutQueryParams(fullUrl: string): string {
		const parts = fullUrl.split('?');
		const urlWithoutQueryParams = parts[0];
		return urlWithoutQueryParams;
	}
}

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ChatMessage } from '../../../../goldstar-internal/src/app/models/interfaces';
import { NotificationReceiveInfoModel } from '../../../../goldstar-internal/src/app/models/models';
import { take } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlingService {
	constructor(private toastr: ToastrService) {}
	showErrorMessage(message: string, withProgressBar = true) {
		this.toastr.error('', message, { progressBar: withProgressBar, positionClass: 'toast-top-right' });
	}

	showSuccessMessage(message: string, withProgressBar = true) {
		this.toastr.success('', message, { progressBar: withProgressBar, positionClass: 'toast-top-right' });
	}

	showWarningMessage(message: string) {
		this.toastr.warning(message, '', { progressBar: true, positionClass: 'toast-top-right', enableHtml: true });
	}

	showInfoMessage(message: string) {
		this.toastr.info(message, '', { progressBar: true, positionClass: 'toast-top-right', enableHtml: true, timeOut: 1000 });
	}

	showMediumLevelNotification(messageModel: NotificationReceiveInfoModel | ChatMessage, message: string, instance: any, onRead?: Function) {
		this.toastr
			.warning(message, '', { timeOut: 0 })
			.onTap.pipe(take(1))
			.subscribe(() => this.toasterClickedHandler(messageModel, instance, onRead));
	}

	showHighLevelNotification(messageModel: NotificationReceiveInfoModel | ChatMessage, message: string, instance: any, onRead?: Function) {
		this.toastr
			.error(message, '', { timeOut: 0 })
			.onTap.pipe(take(1))
			.subscribe(() => this.toasterClickedHandler(messageModel, instance, onRead));
	}

	showInfoLevelNotification(messageModel: NotificationReceiveInfoModel | ChatMessage, message: string, instance: any, onRead?: Function) {
		this.toastr
			.info(message, '', { timeOut: 0 })
			.onTap.pipe(take(1))
			.subscribe(() => this.toasterClickedHandler(messageModel, instance, onRead));
	}

	private toasterClickedHandler(messageModel: NotificationReceiveInfoModel | ChatMessage, instance: any, onRead?: Function) {
		console.log('Toastr clicked');
		if (onRead) {
			onRead(messageModel, instance);
		}
	}
}
